<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import type { Game } from "@/types";

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const props = withDefaults(
	defineProps<{
		category?: string;
		title?: string;
		icon?: string;
		games: Game[];
		rowsPerSlide?: number;
		isShowAll?: boolean;
		isAdminIcon?: boolean;
		hideNextSlidesMobile?: boolean;
		isGuest?: boolean;
	}>(),
	{ rowsPerSlide: 1, isShowAll: true, hideNextSlidesMobile: false }
);

const { t } = useT();
const { open } = useAppModals();
const { handleOpenGame } = useOpenGame(open);
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });

const getDataTid = (index?: number) => {
	if (index === 0) {
		return `slider-first-item`;
	}
	return null;
};

const prepareImgUrl = useImage();
const imagePattern = `url(${prepareImgUrl(`/nuxt-img/card-games/pattern.webp`, {
	format: "webp",
	width: 300
})})`;

const COLUMNS = 12;
const sliderRef = ref();
const gamesLimited = ref(COLUMNS);

const preparedGames: ComputedRef<Game[] | Array<Array<Game>>> = computed(() => {
	if (props.rowsPerSlide === 1) {
		return props.games;
	}

	return props.games.reduce<Array<Array<Game>>>((acc, item, index) => {
		const chunkIndex = Math.floor(index / props.rowsPerSlide);
		if (!acc[chunkIndex]) {
			acc[chunkIndex] = [];
		}
		acc[chunkIndex].push(item);
		return acc;
	}, []);
});

const slicedGames = computed(() => preparedGames.value?.slice(0, gamesLimited.value));

const sliderOptions: KeenSliderOptions = {
	mode: "free",
	slides: { perView: "auto", spacing: 0 },
	slideChanged(slider) {
		if (preparedGames.value?.length === slicedGames.value?.length) {
			return;
		}
		const lastColumn = slider.track.details?.slides[slicedGames.value?.length - 1]?.portion >= 0.5;

		if (lastColumn) {
			gamesLimited.value += COLUMNS;
		}
	}
};

const handlePlayClick = (game: Game) => {
	if (!game.isOnlyForApp) {
		handleOpenGame(game.slug);
	}
};

if (process.client) {
	watch(
		() => props.games?.length,
		() => {
			nextTick(() => {
				sliderRef.value?.slider?.update();
			});
		}
	);
	watch(
		() => slicedGames.value?.length,
		() => {
			nextTick(() => {
				sliderRef.value?.slider?.update();
			});
		}
	);
}
</script>

<template>
	<section :class="[{ hideNextSlidesMobile }]">
		<ASlider ref="sliderRef" :options="sliderOptions">
			<template v-if="rowsPerSlide === 1">
				<MGame
					v-for="(game, index) in slicedGames as Game[]"
					:key="game.id"
					:game="game"
					class="keen-slider__slide"
					:data-tid="getDataTid(index)"
					:image-pattern="imagePattern"
					:insideTitle="game.title || ''"
					:is-guest="isGuest"
					:is-only-for-app="game?.isOnlyForApp"
					:is-favorite="game?.isFavorite"
					:is-new="!!game.isNew"
					:image="`${baseImageUrl}${game?.logo || game?.img}`"
					:game-id="game?.id"
					@toggle-favorite="handleToggleToFavoriteClick(game)"
					@play="handlePlayClick(game)"
				/>
			</template>
			<template v-else>
				<div
					v-for="(item, index) in slicedGames as Game[][]"
					:key="`${category}-${index}`"
					class="keen-slider__slide slide-column"
				>
					<MGame
						v-for="game in item"
						:key="game.id"
						:game="game"
						:data-tid="getDataTid(index)"
						:image-pattern="imagePattern"
						:insideTitle="game.title || ''"
						:is-guest="isGuest"
						:is-only-for-app="game?.isOnlyForApp"
						:is-favorite="game?.isFavorite"
						:is-new="!!game.isNew"
						:image="`${baseImageUrl}${game?.logo || game?.img}`"
						:game-id="game?.id"
						@toggle-favorite="handleToggleToFavoriteClick(game)"
						@play="handlePlayClick(game)"
					/>
				</div>
			</template>
		</ASlider>
	</section>
</template>
<style lang="scss" scoped>
section {
	position: relative;
	max-width: calc(100% - 32px);
	width: 1300px;
	margin: 0 auto;

	&.hideNextSlidesMobile:deep(.keen-slider) {
		mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) calc(100% - 60px), rgba(0, 0, 0, 0) 100%);
	}

	&:deep(.keen-slider) {
		padding-bottom: 40px;
		mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) calc(100% - 60px), rgba(0, 0, 0, 0) 100%);

		@include media-breakpoint-down(md) {
			&:not([data-keen-slider-disabled]) {
				overflow: visible;
			}
		}
	}

	.keen-slider__slide {
		flex-shrink: 0;
		width: calc(172px + 16px);
		padding-right: 16px;

		@include media-breakpoint-down(md) {
			width: calc(124px + 16px);
			padding-right: 16px;

			&:last-of-type {
				padding-right: 0;
				width: 124px;
			}
		}

		&.slide-column {
			display: flex;
			flex-direction: column;
			gap: 40px;
		}
	}
}
</style>
